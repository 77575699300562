
import { mdiMagnify } from "@mdi/js"
import searchService from "@/services/search.service"
import schoolService from "@/services/school.service"
import ProgressCircular from "@/components/base/ProgressCircular"
const debounce = require("lodash.debounce")
export default {
    name: "SchoolBaseSearch",
    components: {
        ProgressCircular,
    },
    /**
     * select: school selected (v-model)
     */
    emits: ["select"],
    props: {
        /** selected school object {name, id} */
        value: {
            type: Object,
            default: () => {},
        },
        /** location object:
         *      latitude
         *      longitude
         *      name
         **/
        location: {
            type: Object,
            default: () => {},
        },
        /** initial search query */
        initialSearchQuery: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            mdiMagnify,
            searchQuery: "",
            schoolResults: [],
            isSearchLoading: false,
            isCreatingSchool: false,
            debouncedSearch: debounce(this.performSearch, 500),
            showCreatingSchoolError: false,
        }
    },
    computed: {
        model: {
            get() {
                return this.value
            },
            set(index) {
                this.$emit("select", index)
            },
        },
        searchQueryModel: {
            get() {
                return this.searchQuery
            },
            set(value) {
                this.searchQuery = value.trimEnd()
                this.isSearchLoading = true
                this.schoolResults = []
                this.debouncedSearch()
            },
        },
        isMobile() {
            // TODO: move a mixin or a helper function
            const isIpad =
                navigator.userAgent.match(/Mac/) &&
                navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2
            return !this.$ua.isFromPc() || isIpad
        },
        isSearchQueryValid() {
            return (
                typeof this.searchQueryModel === "string" &&
                this.searchQueryModel.length !== 1
            )
        },
        isSearchQueryEmpty() {
            return !this.searchQueryModel
        },
        hasResults() {
            return this.schoolResults && this.schoolResults.length > 0
        },
        hasExactMatch() {
            const query = this.searchQueryModel
            if (!query) return false

            const bestResult = this.hasResults
                ? this.schoolResults[0].name
                : null
            if (!bestResult) return false

            return bestResult === query
        },
        showCreateSchoolBtn() {
            return (
                this.isSearchQueryValid &&
                !this.isSearchQueryEmpty &&
                !this.hasExactMatch
            )
        },
        createNewSchoolClasses() {
            return {
                "cursor-default": this.isCreatingSchool,
                "school-base-search__results__new--loading":
                    this.isCreatingSchool,
                "school-base-search__results__new--shake":
                    this.showCreatingSchoolError,
                "grey-5--text": this.hasResults,
                "grey-1": this.hasResults,
                "white--text": !this.hasResults,
                black: !this.hasResults,
            }
        },
    },
    created() {
        this.searchQuery = this.initialSearchQuery
        this.isSearchLoading = true
        this.performSearch()
    },
    methods: {
        async performSearch() {
            if (this.isSearchQueryEmpty) {
                this.schoolResults = await searchService.searchBaseSchool({
                    limit: 10,
                    latitude: this.location ? this.location.latitude : null,
                    longitude: this.location ? this.location.longitude : null,
                })
            } else {
                this.schoolResults = await searchService.searchBaseSchool({
                    name: this.searchQueryModel,
                    limit: 10,
                })
            }
            this.isSearchLoading = false
        },
        resetSearch() {
            this.searchQuery = ""
            this.schoolResults = ""
        },
        selectSchool(school) {
            if (this.isCreatingSchool) return
            this.model = school
        },
        async createSchool() {
            if (this.isCreatingSchool) return
            this.isCreatingSchool = true
            try {
                this.model = await schoolService.createBaseSchool(
                    this.searchQueryModel
                )
            } catch {
                this.showCreatingSchoolError = true
                setTimeout(() => {
                    this.showCreatingSchoolError = false
                }, 1000)
            }
            this.isCreatingSchool = false
        },
        setSchool(school) {
            this.searchQueryModel = school.name
            this.$emit("select", school)
        },
    },
}
