
import { mdiChevronUp } from "@mdi/js"
import Button from "@/components/base/Button"
import SchoolBaseSearch from "@/components/search/SchoolBaseSearch"
import Card from "@/components/base/card/Card"

export default {
    name: "ChatInputSchool",
    components: { Card, SchoolBaseSearch, Button },
    /**
     * send: event to send the message, full data
     * input: named selection
     */
    emits: ["send", "input"],
    inject: ["formData"],
    props: {
        /** input is disabled (vuetify prop)  */
        disabled: {
            type: Boolean,
            default: false,
        },
        /** selected value (v-model)  */
        value: {
            type: String,
            default: "",
        },
        /** input label (Vuetify prop) */
        label: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            mdiChevronUp,
            showSearch: false,
            showActivator: true,
            search: "",
        }
    },
    computed: {
        location() {
            return this.formData?.["region"]?.data
        },
    },
    methods: {
        onSchoolSelect(school) {
            this.$emit("input", school.name)
            this.$emit("send", school)
            this.closeSearch()
        },
        openSearch() {
            this.isSearchLoading = true
            this.showActivator = false
            this.showSearch = true
        },
        closeSearch() {
            this.showSearch = false
            setTimeout(() => {
                this.showActivator = true
            }, 400)
        },
    },
}
